import Layout from '../components/layout'

import React from 'react'

export default function sales() {
  return (
    <Layout>
      <div className="about-us">
        <div className="header">
          <div className="container">
            <div className="row">
              <div className="columns twelve">
                <h1 className="center-text">Sales</h1>
                <p>Introduction to our story </p>
                <p>lorem ipsum dolor sit amet</p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{ marginTop: '100px', marginBottom: '60px' }}
        >
          <div className="row">
            <div className="columns twelve">
              <h2>Our Sales Service</h2>
            </div>
          </div>

          <div className="row">
            <div className="eight columns">
              <p>
                A description of this service and a list of what this service
                covers. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua.
              </p>

              <p>
                Ut enim ad minima veniam, quis nostrum exercitationem ullam
                corporis suscipit laboriosam, nisi ut aliquid ex ea commodi
                consequatur? Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>

              <p>
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <div className="four columns">
              <ul>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </li>
                <li>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia magni dolores eos qui ratione
                </li>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt.
                </li>
                <li>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                  odit aut fugit, sed quia
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <h3 className="center-text">Cars for Sales</h3>
              <p>
                Copy about the ebay shop ut enim ad minim veniam, quis nostrud
                exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate
                velit esse cillum.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="columns twelve">
              <div className="salesBox">
                <div id="auction-nudge-items" className="auction-nudge"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
